// src/components/PolicyPage.js

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify
import { Helmet } from 'react-helmet-async'; // Import Helmet
import './PolicyPage.css';

const PolicyPage = () => {
  const { policyType } = useParams();
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const response = await fetch(`${backendURL}/api/policies/${policyType}`);
        if (response.ok) {
          const data = await response.json();
          setPolicy(data);
        } else {
          setError('Policy not found.');
        }
      } catch (err) {
        console.error('Error fetching policy:', err);
        setError('Error fetching policy.');
      } finally {
        setLoading(false);
      }
    };

    fetchPolicy();
  }, [policyType, backendURL]);

  if (loading) {
    return <div className="policy-page">Loading...</div>;
  }

  if (error) {
    return <div className="policy-page">{error}</div>;
  }

  // Sanitize the HTML content to prevent XSS attacks
  const sanitizedContent = DOMPurify.sanitize(policy.content);

  // Generate meta description by extracting a summary from the policy content
  const generateMetaDescription = (content) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const text = div.textContent || div.innerText || '';
    return text.substring(0, 160); // Limit to 160 characters
  };

  const metaDescription = generateMetaDescription(policy.content);

  // Construct absolute URLs for images (if applicable)
  const ogImage = `${window.location.origin}/og-image.png`; // Replace with relevant image
  const twitterImage = `${window.location.origin}/twitter-image.png`; // Replace with relevant image

  return (
    <>
      {/* Helmet for SEO and Social Sharing */}
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{policy.title} | Bright Happy Books</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content={`Policy, ${policy.title}, Bright Happy Books, Privacy, Terms, ${policyType}`}
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={`${policy.title} | Bright Happy Books`} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:alt" content={`${policy.title} Image`} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${policy.title} | Bright Happy Books`} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={twitterImage} />
        <meta name="twitter:image:alt" content={`${policy.title} Image`} />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${policy.title} | Bright Happy Books",
              "description": "${metaDescription.replace(/"/g, '\\"')}",
              "url": "brighthappybooks.com",
              "mainEntity": {
                "@type": "WebPage",
                "name": "${policy.title}",
                "description": "${metaDescription.replace(/"/g, '\\"')}"
              }
            }
          `}
        </script>
      </Helmet>

      <div className="policy-page">
        <h1>{policy.title}</h1>
        {/* Render sanitized policy content as HTML */}
        <div
          className="policy-content"
          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        />
        <p>Last updated: {new Date(policy.updated_at).toLocaleDateString()}</p>
        {/* Link to the Contact Page */}
        <p>
          If you have any questions, please visit our <Link to="/contact">Contact Page</Link>.
        </p>
      </div>
    </>
  );
};

export default PolicyPage;
